@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../assets/fonts/CabinetGrotesk-Regular.woff2') format('woff2'),
    url('../assets/fonts/CabinetGrotesk-Regular.woff') format('woff'),
    url('../assets/fonts/CabinetGrotesk-Regular.ttf') format('truetype');
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../assets/fonts/CabinetGrotesk-Medium.woff2') format('woff2'),
    url('../assets/fonts/CabinetGrotesk-Medium.woff') format('woff'),
    url('../assets/fonts/CabinetGrotesk-Medium.ttf') format('truetype');
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../assets/fonts/CabinetGrotesk-Bold.woff2') format('woff2'),
    url('../assets/fonts/CabinetGrotesk-Bold.woff') format('woff'),
    url('../assets/fonts/CabinetGrotesk-Bold.ttf') format('truetype');
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: 'CabinetGrotesk';
  src: url('../assets/fonts/CabinetGrotesk-Extrabold.woff2') format('woff2'),
    url('../assets/fonts/CabinetGrotesk-Extrabold.woff') format('woff'),
    url('../assets/fonts/CabinetGrotesk-Extrabold.ttf') format('truetype');
  font-weight: 800;
  font-display: swap;
  font-style: normal;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'CabinetGrotesk', sans-serif;
  scroll-behavior: smooth;
  font-size: 16px;
  line-height: 24px;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
